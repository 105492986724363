import { useTranslation } from '@elzeard/common-components';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, Fragment, HTMLInputTypeAttribute, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ErrorMessage } from '../../../common/components/ErrorMessage';
import HookedTextInput from '../../../common/components/field/InputField';
import { RightFormModal } from '../../../common/components/modal/RightFormModal';
import { Setter } from '../../../common/utils/types';
import { handleStringEmpty } from '../../../common/utils/yupUtils';
import { IEditedProject, IStepProps } from './PanelEditProject';
import { Form, FormGroup } from './styledComponents';

export interface IStep1Props extends IStepProps {
  setEditedFarm: Setter<IEditedProject>;
}
export const Step1: FC<IStep1Props> = ({ editedFarm, handleClose, setEditedFarm, setFormStep }) => {
  const { t } = useTranslation();
  const step1Schema = yup.object({
    projectTitle: yup.string().nullable(), //.required(t('project:edit.form.fields.projectTitle.error.required')),
    street1: yup.string().nullable(), //.required(t('project:edit.form.fields.street1.error.required')),
    street2: yup.string().nullable(),
    postalCode: yup.string().nullable(), //.required(t('project:edit.form.fields.postalCode.error.required'))
    // .typeError(t('project:edit.form.fields.postalCode.error.type')),
    city: yup.string().nullable(), //.required(t('project:edit.form.fields.city.error.required')),
    longitude: yup.number().transform(handleStringEmpty).typeError(t('project:edit.form.fields.longitude.error.type')),
    latitude: yup.number().transform(handleStringEmpty).typeError(t('project:edit.form.fields.latitude.error.type')),
  });
  interface IStep1Form extends yup.InferType<typeof step1Schema> {}
  interface IFormGroup {
    title: string;
    required?: boolean;
    inputs: Array<{ placeholder: string; fieldName: keyof IStep1Form; type?: HTMLInputTypeAttribute }>;
  }

  const formInputGroups: IFormGroup[] = [
    {
      title: t('project:edit.form.groups.title'),
      required: true,
      inputs: [
        {
          placeholder: t('project:edit.form.fields.projectTitle.name'),
          fieldName: 'projectTitle',
        },
      ],
    },
    {
      title: t('project:edit.form.groups.address'),
      inputs: [
        {
          placeholder: t('project:edit.form.fields.street1.name'),
          fieldName: 'street1',
        },
        {
          placeholder: t('project:edit.form.fields.street2.name'),
          fieldName: 'street2',
        },
        {
          placeholder: t('project:edit.form.fields.postalCode.name'),
          fieldName: 'postalCode',
        },
        {
          placeholder: t('project:edit.form.fields.city.name'),
          fieldName: 'city',
        },
      ],
    },
    {
      title: t('project:edit.form.groups.coordinates'),
      inputs: [
        {
          placeholder: t('project:edit.form.fields.longitude.name'),
          fieldName: 'longitude',
        },
        {
          placeholder: t('project:edit.form.fields.latitude.name'),
          fieldName: 'latitude',
        },
      ],
    },
  ];

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IStep1Form>({
    resolver: yupResolver(step1Schema),
  });

  useEffect(() => {
    if (!editedFarm) {
      return;
    }
    reset({
      projectTitle: editedFarm.title,
      street1: editedFarm.address?.street1,
      street2: editedFarm.address?.street2,
      postalCode: editedFarm.address?.postalCode, // ? parseInt(editedFarm.address.postalCode) : null,
      city: editedFarm.address?.city,
      latitude: editedFarm.geopoint?.latitude,
      longitude: editedFarm.geopoint?.longitude,
    });
  }, [reset, editedFarm]);

  const onSubmit: SubmitHandler<IStep1Form> = async (formData: IStep1Form) => {
    const convertedForm: IEditedProject = {
      title: formData.projectTitle,
      address: {
        street1: formData.street1,
        street2: formData.street2,
        postalCode: formData.postalCode?.toString(),
        city: formData.city,
      },
      geopoint:
        formData.latitude && formData.longitude
          ? {
              latitude: formData.latitude,
              longitude: formData.longitude,
            }
          : null,
    };
    setEditedFarm((editedFarm) =>
      editedFarm
        ? {
            ...editedFarm,
            ...convertedForm,
          }
        : convertedForm,
    );
    setFormStep(2);
  };

  return (
    <RightFormModal
      title={editedFarm?.id ? `${t('project:edit.title')} ${editedFarm.title}` : t('project:edit.newProjectTitle')}
      confirmButtonLabelKey="common:button.next"
      onCancel={handleClose}
      onConfirm={handleSubmit(onSubmit)}
      blueBackground
    >
      <Form>
        {formInputGroups.map((group, groupIndex) => (
          <FormGroup key={groupIndex}>
            {group.inputs.map((input, inputIndex) => (
              <Fragment key={groupIndex + inputIndex}>
                <HookedTextInput
                  label={inputIndex === 0 && group.title}
                  required={group.required}
                  fieldName={input.fieldName}
                  placeholder={input.placeholder}
                  type={input.type}
                  register={register}
                  errors={errors}
                />
                <ErrorMessage message={errors[input.fieldName]?.message} />
              </Fragment>
            ))}
          </FormGroup>
        ))}
      </Form>
    </RightFormModal>
  );
};
