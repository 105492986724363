import { ToHandlers } from '@elzeard/common-components';
import { BaseProjectCommands } from './commands';
import { BaseProjectPageState, ProjectPageState } from './state';
import { buildBaseState } from './state-build';
import { buildAllPossibleParentProducts } from './state-init';
import { buildParentItineraries } from './useCropItineraries';

export const baseProjectCommandHandler: ToHandlers<BaseProjectPageState<any>, BaseProjectCommands<any>> = {
  save(previousState) {
    if (!previousState.isSaving) {
      return {
        ...previousState,
        isSaving: true,
      };
    } else {
      return previousState;
    }
  },
  saveSuccess(previousState, command) {
    const {
      //createdItineraries, deletedItineraries, updatedItineraries,
      updatedObject,
    } = command;

    const parentItineraries = buildParentItineraries(updatedObject.hasCropItineraries, false);
    const baseState = buildAllPossibleParentProducts({
      parentItineraries,
      time: previousState.time,
      farmingSystem: previousState.farmingSystem,
    });

    const updatedBaseState = buildBaseState(updatedObject, baseState); // reinitBaseState(previousState));

    const updatedState: BaseProjectPageState<any> = {
      ...updatedBaseState,
      farmId: previousState.farmId,
      farmingSystem: previousState.farmingSystem,
      removedOutlets: previousState.removedOutlets,
      time: previousState.time,
      initializePageState: previousState.initializePageState,
      updatePageState: previousState.updatePageState,
      isSaving: false,
      actions: [],
      error: null,
    };
    return {
      ...updatedState,
      ...previousState.initializePageState(updatedState, previousState),
      initializePageState: previousState.initializePageState,
      updatePageState: previousState.updatePageState,
    };
  },
  saveError(previousState, command) {
    return {
      ...previousState,
      isSaving: false,
      error: command.error.message, // TODO
    };
  },
  enterPage: (previousState, command) => {
    const pageState = command.enter(previousState, previousState);
    return {
      ...previousState,
      ...pageState,
      initializePageState: command.enter,
      updatePageState: command.update,
    };
  },
  leavePage: (previousState, command) => {
    return {
      ...command.function(previousState),
      initializePageState: null,
    };
  },
  refreshPlots: (previousState, command) => {
    if (command.updatedPlots && command.updatedPlots !== previousState.basePlots) {
      const updatedState: ProjectPageState<unknown> = {
        ...previousState,
        basePlots: command.updatedPlots,
      };
      return {
        ...updatedState,
        ...(updatedState.initializePageState(updatedState, previousState) as {}),
      };
    }
    return previousState;
  },
};
