import { gql } from '@apollo/client';

export const connections = {
  cache_FarmCell: gql`
    fragment FarmCell on Farm_Farm {
      id
      hasProductionCell {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
  cache_CellPlots: gql`
    fragment CellPlots on ProductionCell {
      id
      hasPlot {
        edges {
          node {
            id
          }
        }
      }
    }
  `,
};

export const DurationFragment = gql`
  fragment DurationFragment on Itk_Duration {
    numericDuration # float
    unitType
  }
`;

const ProductionNeedFragment = gql`
  fragment ProductionNeedFragment on ProductionNeed {
    concernsClient {
      id
      # name
      # clientCategory {
      #   id
      # }
    }
    clientCategory
    unitPrice
    needs {
      year
      week
      volume
    }
    prices {
      year
      week
      volume
    }
  }
`;

const PlannedSerieFragment = gql`
  fragment PlannedSerieFragment on PlannedSeries {
    beginDate
    endDate
    matureDays
    expectedLostRate
    needs {
      year
      week
      volume
    }
    hasNeededSurface {
      parameterValue
      hasSurfaceUnit {
        id
      }
    }
    hasCropProductionPeriod {
      begin
      end
      yield {
        value
        unit
      }
      storageDays
    }
    hasPosition {
      edges {
        node {
          # id # no id on this type !?!
          isLocated {
            id
          }
          startPosition
          hasLengthPosition {
            parameterValue
            hasLengthUnit {
              id
            }
          }
          hasPositionSurface {
            parameterValue
            hasSurfaceUnit {
              id
            }
          }
        }
      }
    }
  }
`;

const ProductFragment = gql`
  ${DurationFragment}
  ${ProductionNeedFragment}
  ${PlannedSerieFragment}
  fragment ProductFragment on PlannedProduct {
    hasCultivar {
      id
      prefCommonName
      isCultivarOf {
        id
        prefCommonName
        plantFamilyScientificName
      }
    }
    hasCropItinerary {
      id
      isSubItineraryOf {
        id
      }
    }
    hasCultureMode {
      id
    }
    name
    disabled
    #expectedYield # TO REMOVE for data migration only
    expectedLostRate # TO REMOVE for data migration only
    hasProductionNeed {
      edges {
        node {
          id
          ...ProductionNeedFragment
        }
      }
    }
    hasPlannedSeries {
      edges {
        node {
          id
          ...PlannedSerieFragment
        }
      }
    }
    hasHarvestQuantityUnit {
      id
    }
  }
`;

const PurchaseResaleFragment = gql`
  ${DurationFragment}
  ${ProductionNeedFragment}
  fragment PurchaseResaleFragment on PlannedPurchaseResale {
    hasCultivar {
      id
      prefCommonName
      isCultivarOf {
        id
        prefCommonName
        plantFamilyScientificName
      }
    }
    name
    hasProductionNeed {
      edges {
        node {
          id
          ...ProductionNeedFragment
        }
      }
    }
    hasHarvestQuantityUnit {
      id
    }
  }
`;

const BasketOfferFragment = gql`
  fragment BasketOfferFragment on PlannedBasketOffer {
    id
    sellingPrice
    concernsClient {
      id
    }
    weeklyBaskets {
      week
      year
      number
    }
  }
`;

const PepinierePlotFragment = gql`
  fragment PepinierePlotFragment on Plot {
    id
    identifierPlot
    belongsToProductionCell {
      id
      productionCellName
    }
    hasCultureMode {
      id
    }
    hasCultivableSurface {
      parameterValue
      hasParameterUnit {
        id
      }
    }
    hasTotalSurface {
      parameterValue
      hasParameterUnit {
        id
      }
    }
    hasLength {
      parameterValue
      hasParameterUnit {
        id
      }
    }
    hasWidth {
      parameterValue
      hasParameterUnit {
        id
      }
    }
    hasFootPassWidth {
      parameterValue
      hasParameterUnit {
        id
      }
    }
    hasCultivationBed {
      edges {
        node {
          id
          hasWidth {
            parameterValue
            hasParameterUnit {
              id
            }
          }
          hasLength {
            parameterValue
            hasParameterUnit {
              id
            }
          }
          bedNumber
        }
      }
    }
  }
`;

const PepiniereProjectFragment = gql`
  ${PepinierePlotFragment}
  ${ProductFragment}
  ${PurchaseResaleFragment}
  ${BasketOfferFragment}
  fragment PepiniereProjectFragment on Farm_Farm {
    id
    name
    geolocation {
      lat
      long
    }
    hasProductionCell {
      edges {
        node {
          id
          productionCellName
          hasPlot {
            edges {
              node {
                ...PepinierePlotFragment
              }
            }
          }
        }
      }
    }
    hasCulturalContext {
      cultivatesIn {
        edges {
          node {
            id
          }
        }
      }
    }
    isPepiniereProject {
      id
      beginDatePlan
      endDatePlan
      lastSaveAt
      hasProduct {
        edges {
          node {
            id
            ...ProductFragment
          }
        }
      }
      hasPurchaseResale {
        edges {
          node {
            id
            ...PurchaseResaleFragment
          }
        }
      }
      hasOutlet {
        edges {
          node {
            id
            name
            isDisabled
            salesTarget {
              year
              week
              number
            }
            hasBasketOffer {
              edges {
                node {
                  ...BasketOfferFragment
                }
              }
            }
            concernsClient {
              id
            }
            hasDefaultPrices {
              cropItineraryId
              distributionCircuit
              priceLevel
              value
            }
            distributionCircuitPrices
            priceLevel
          }
        }
      }
    }
  }
`;

export const GET_PEPINIERE_PROJECT = gql`
  ${PepiniereProjectFragment}
  query GetPepiniereProject($id: ID!) {
    farmForPepiniere(id: $id) {
      ...PepiniereProjectFragment
      hasCropItineraries {
        ...ItkFragment
        concernsPlant {
          ...MiniPlantFragment
        }
        isSubItineraryOf {
          id
        }
        isDerivedFromItinerary {
          id
        }
      }
    }
  }
`;

export const UPDATE_PEPINIERE_PROJECT = gql`
  ${PepiniereProjectFragment}
  mutation UpdatePepiniereProject(
    $farmId: ID!
    $farmInput: Farm_FarmInput
    $projectInput: PepiniereProjectInput
    $itksInput: PepiniereProjectItksInput
  ) {
    updatePepiniereProject(farmId: $farmId, farmInput: $farmInput, projectInput: $projectInput, itksInput: $itksInput) {
      updatedObject {
        ...PepiniereProjectFragment
        hasCropItineraries {
          ...ItkFragment
          concernsPlant {
            ...MiniPlantFragment
          }
          isSubItineraryOf {
            id
          }
          isDerivedFromItinerary {
            id
          }
        }
      }
      # deletedItineraries
      # createdItineraries {
      #   ...ItkFragment
      #   concernsPlant {
      #     ...MiniPlantFragment
      #   }
      #   isSubItineraryOf {
      #     id
      #   }
      #   isDerivedFromItinerary {
      #     id
      #   }
      # }
      # updatedItineraries {
      #   ...ItkFragment
      #   concernsPlant {
      #     ...MiniPlantFragment
      #   }
      #   isSubItineraryOf {
      #     id
      #   }
      #   isDerivedFromItinerary {
      #     id
      #   }
      # }
    }
  }
`;

export const CREATE_PLOT = gql`
  ${PepinierePlotFragment}
  mutation CreatePlot($farmId: ID!, $input: PlotInput!) {
    createPepinierePlot(farmId: $farmId, input: $input) {
      createdObject {
        ...PepinierePlotFragment
        belongsToProductionCell {
          id
        }
      }
    }
  }
`;

export const DELETE_PLOT = gql`
  ${PepiniereProjectFragment}
  mutation DeletePlot($plotId: ID!, $farmId: ID!, $projectInput: PepiniereProjectInput!) {
    removePepinierePlot(plotId: $plotId) {
      deletedId
    }
    updatePepiniereProject(farmId: $farmId, projectInput: $projectInput) {
      updatedObject {
        ...PepiniereProjectFragment
      }
    }
  }
`;
