import { getPlantFamilyColorsFromScientificName, PlantFamilyColorsName } from '@elzeard/common-components';
import {
  CommercialOutletEnum,
  CultureModeEnum,
  differenceInRDateWeeks,
  FarmingSystemEnum,
  getRdateAsWeek,
  HarvestUnitEnum,
  parseRDate,
  PriceUnitEnum,
  RDate_Week,
  rDateAsDate,
  volumeUnitBy,
  YieldUnitEnum,
} from '@elzeard/shared-dimensions';
import { sortBy } from 'lodash';
import { useMemo } from 'react';
import {
  GetPepiniereProjectQuery,
  GetReferenceItinerariesQuery,
  Itk_Duration,
  Plant_TimeDuration,
  PracticeTypeEnum,
  TechnicalOperationConnection,
  useGetReferenceItinerariesQuery,
} from '../common/generated/graphql';
import referenceItksJSON from '../resources/itks_reference.json';
import { VariableWithMinMax } from '../shared-elzeard/variables';

export interface Rotation {
  interval: Pick<Itk_Duration, 'numericDuration' | 'unitType'>;
}
export interface Itinerary {
  id: string;
  name: string;
  isUpdated: boolean;
  isReferenceItinerary: boolean;
  referenceItineraryId: string | null;
  plantId: string;
  plantName: string;
  plantFamily: string;
  plantFamilyColors: PlantFamilyColorsName;
  implantationWeek: RDate_Week;
  harvest: {
    begin: RDate_Week;
    end: RDate_Week;
  };
  storageDurationWeeks: number;
  yield: VariableWithMinMax<YieldUnitEnum>;
  quantityUnit: HarvestUnitEnum;
  cultureModes: Array<CultureModeEnum>;
  farmingSystem: FarmingSystemEnum;
  prices: Record<CommercialOutletEnum, VariableWithMinMax<PriceUnitEnum>>;
  rotation: { interval: Plant_TimeDuration }[];
  itkRotation: Rotation;
}

export interface ParentItinerary extends Itinerary {
  children: Array<ChildItinerary>;
}
export interface ChildItinerary extends Itinerary {
  parent: ParentItinerary;
}

export function useGrosHack(): { data: GetReferenceItinerariesQuery; loading: boolean; error: any } {
  return {
    //@ts-ignore
    data: referenceItksJSON,
    loading: false,
    error: null,
  };
}

export function buildParentItineraries(data: ItineraryFromDB[], isReferenceItinerary: boolean) {
  type TempItinerary = Itinerary & { parentId?: string };
  const itinerariesById = data.reduce((result, node) => {
    const parentId = node.isSubItineraryOf?.id;
    const convertedItinerary: TempItinerary = convertItinerary(node, isReferenceItinerary);
    if (convertedItinerary) {
      convertedItinerary.parentId = parentId;
      result[convertedItinerary.id] = convertedItinerary;
    }
    return result;
  }, {} as Record<string, TempItinerary>);
  const parentItineraries: Record<string, ParentItinerary> = Object.values(itinerariesById).reduce(
    (parentItineraries, { parentId, ...itinerary }) => {
      if (parentId) {
        let parent = parentItineraries[parentId];
        if (!parent) {
          parent = itinerariesById[parentId];
          if (parent) {
            // false only when there are corrupted itineraries in the DB
            delete parent.parentId;
            parent.children = [];
            parentItineraries[parentId] = parent;
          }
        }
        if (parent) {
          (itinerary as ChildItinerary).parent = parent;
          itinerary.prices = {
            ...parent.prices,
            ...itinerary.prices,
          };
          parent.children.push(itinerary);
        } else {
          console.warn('WTF ?', itinerary, parentId);
        }
      } else {
        (itinerary as ParentItinerary).children = [];
        parentItineraries[itinerary.id] = itinerary;
      }
      return parentItineraries;
    },
    {},
  );
  return sortBy(
    Object.values(parentItineraries).map((parent) => {
      if (!parent.children.length) {
        // cas particulier d'un ITK parent sans enfant => il devient son propre enfant
        const { children, ...child } = parent;
        parent.children.push({ ...child, parent });
      }
      return parent;
    }),
    (itk) => itk.name,
  );
}

export function useReferenceCropItineraries() {
  const { data, error, loading } = useGetReferenceItinerariesQuery();
  // const { data, error, loading } = useGrosHack();
  const cropItineraries: Array<ParentItinerary> = useMemo(() => {
    if (loading || error) {
      return [];
    }
    return buildParentItineraries(
      data.knowledgeBaseItineraries.edges.map(({ node }) => node),
      true,
    );
  }, [data, error, loading]);
  return {
    error,
    loading,
    cropItineraries,
  };
}

type ItineraryFromDB = GetPepiniereProjectQuery['farmForPepiniere']['hasCropItineraries'][0];

const implantationTaskTypes = {
  [PracticeTypeEnum.Broadcast]: true,
  [PracticeTypeEnum.SeedingInRows]: true,
  [PracticeTypeEnum.Plantation]: true,
};
function convertItinerary(dbItinerary: ItineraryFromDB, isReferenceItinerary: boolean): Itinerary {
  const { id, label, hasOperationMember, concernsPlant, hasYieldRange, recommendsRotation } = dbItinerary;
  const itkOperations = hasOperationMember as TechnicalOperationConnection;
  const implantationTask = itkOperations.edges.find(
    ({ node }) => implantationTaskTypes[PracticeTypeEnum[node.practiceType]],
  );
  const harvestTask = itkOperations.edges.find(
    ({ node }) => PracticeTypeEnum[node.practiceType] === PracticeTypeEnum.Harvest,
  );
  const storageTask = itkOperations.edges.find(
    ({ node }) => PracticeTypeEnum[node.practiceType] === PracticeTypeEnum.Storage,
  );
  if (!implantationTask || !harvestTask) {
    console.log('Missing task', dbItinerary, implantationTask, harvestTask);
    return null;
  }
  if (
    !implantationTask.node?.hasRelativePeriod?.hasBeginning?.inRDate ||
    !implantationTask.node?.hasRelativePeriod?.hasEnding?.inRDate ||
    !harvestTask.node?.hasRelativePeriod?.hasBeginning?.inRDate ||
    !harvestTask.node?.hasRelativePeriod?.hasEnding?.inRDate
  ) {
    console.log('Missing period boundary', dbItinerary, implantationTask, harvestTask);
    return null;
  }
  if (!concernsPlant) {
    console.log('Missing plant', dbItinerary);
    return null;
  }
  const yieldValue: VariableWithMinMax<YieldUnitEnum> = hasYieldRange && {
    value: hasYieldRange.value,
    unit: hasYieldRange.unit as YieldUnitEnum,
    min: hasYieldRange.min,
    max: hasYieldRange.max,
  };
  if (!yieldValue) {
    console.log('No yield, itk ignored', dbItinerary);
    return null;
  }
  const implantationWeek = getRdateAsWeek(parseRDate(implantationTask.node.hasRelativePeriod.hasBeginning.inRDate));
  const harvestBegin = getRdateAsWeek(parseRDate(harvestTask.node.hasRelativePeriod.hasBeginning.inRDate));
  const harvestEnd = getRdateAsWeek(parseRDate(harvestTask.node.hasRelativePeriod.hasEnding.inRDate), true);
  if (!implantationWeek || !harvestBegin || !harvestEnd) {
    console.log('Invalid date', implantationWeek, harvestBegin, harvestEnd, implantationTask, harvestTask);
    return null;
  }
  if (rDateAsDate(harvestEnd).getTime() < rDateAsDate(harvestBegin).getTime()) {
    console.log('Harvest end before begin', dbItinerary, harvestBegin, harvestEnd);
    return null;
  }
  if (rDateAsDate(harvestBegin).getTime() < rDateAsDate(implantationWeek).getTime()) {
    console.log('Harvest begin before implantation', dbItinerary, implantationWeek, harvestBegin);
    return null;
  }
  let storageDurationWeeks = null;
  if (storageTask) {
    const storageEnd = getRdateAsWeek(parseRDate(storageTask.node.hasRelativePeriod.hasEnding.inRDate), true);
    storageDurationWeeks = differenceInRDateWeeks(storageEnd, harvestEnd);
  }
  const farmingSystem =
    dbItinerary.dependsOnCulturalContext?.cultivatesIn.edges?.map(({ node }) => node.id as FarmingSystemEnum)?.[0] ||
    null;
  return {
    id,
    isUpdated: false,
    isReferenceItinerary,
    referenceItineraryId: dbItinerary.isDerivedFromItinerary?.id,
    plantId: concernsPlant.id,
    plantName: concernsPlant.prefCommonName,
    plantFamily: concernsPlant.plantFamilyScientificName,
    plantFamilyColors: getPlantFamilyColorsFromScientificName(concernsPlant.plantFamilyScientificName),
    name: label,
    implantationWeek,
    harvest: {
      begin: harvestBegin,
      end: harvestEnd,
    },
    storageDurationWeeks,
    yield: yieldValue,
    quantityUnit: yieldValue && volumeUnitBy[yieldValue.unit],
    cultureModes: dbItinerary.hasCultureMode.edges.map(({ node }) => node.id as CultureModeEnum),
    farmingSystem,
    rotation: concernsPlant.hasRotation?.edges.map((edge) => ({ interval: edge.node.rotationInterval })),
    itkRotation: recommendsRotation && { interval: recommendsRotation.rotationInterval },
    prices: dbItinerary.hasIndicativePrice.reduce((acc, price) => {
      acc[price.commercialOutlet] = {
        value: price.value,
        min: price.min,
        max: price.max,
        unit: price.unit,
      };
      return acc;
    }, {} as Itinerary['prices']),
  };
}
