import { getMonth, getMonthInterval } from '@elzeard/common-components';
import { FarmingSystemEnum, fromXSDDate } from '@elzeard/shared-dimensions';
import { useMemo } from 'react';
import { useGetPepiniereProjectQuery } from '../common/generated/graphql';
import { BaseProjectPageState } from './state';
import { buildBaseState } from './state-build';
import { buildAllPossibleParentProducts } from './state-init';
import { buildParentItineraries, ParentItinerary } from './useCropItineraries';

export function usePepiniereProject(farmId: string, cropItineraries: Array<ParentItinerary>) {
  const { data, error, loading } = useGetPepiniereProjectQuery({
    variables: {
      id: farmId,
    },
    skip: !farmId,
  });
  const baseState: BaseProjectPageState = useMemo(() => {
    if (loading || error || !farmId || !cropItineraries || cropItineraries.length === 0) {
      return null;
    }
    const { beginDatePlan, endDatePlan /*, lastSaveAt */ } = data.farmForPepiniere.isPepiniereProject;
    const time = getMonthInterval(getMonth(fromXSDDate(beginDatePlan)), getMonth(fromXSDDate(endDatePlan)));
    const farmingSystem = data.farmForPepiniere.hasCulturalContext.cultivatesIn.edges[0].node.id as FarmingSystemEnum;
    // const initialBaseState = buildAllPossibleParentProducts(cropItineraries, time, farmingSystem);

    const parentItineraries = buildParentItineraries(data.farmForPepiniere.hasCropItineraries, false);
    const initialBaseState = buildAllPossibleParentProducts({
      parentItineraries,
      time,
      farmingSystem,
    });

    return {
      farmId: data.farmForPepiniere.id,
      farmingSystem,
      time,
      ...buildBaseState(data.farmForPepiniere, initialBaseState),
      actions: [],
      removedOutlets: [],
      isSaving: false,
      initializePageState: null,
      updatePageState: null,
    };
  }, [cropItineraries, data, error, farmId, loading]);
  return {
    error,
    loading,
    baseState,
  };
}
