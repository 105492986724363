import { useTranslation } from '@elzeard/common-components';
import { HarvestUnitEnum } from '@elzeard/shared-dimensions';
import { yupResolver } from '@hookform/resolvers/yup';
import { identity, sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';
import { ErrorMessage } from '../../../common/components/ErrorMessage';
import { Checkbox } from '../../../common/components/field/Checkbox';
import HookedTextInput from '../../../common/components/field/InputField';
import { RightFormModal } from '../../../common/components/modal/RightFormModal';
import Spinner from '../../../common/components/Spinner';
import { colorStyle } from '../../../common/style/colors';
import { getTextStyle } from '../../../common/style/text';
import { Setter } from '../../../common/utils/types';
import { translateHarvestUnitEnum } from '../../../shared-elzeard/constants/translate-constant';
import { ParentProduct } from '../../state';
import { buildAllPossibleParentProducts } from '../../state-init';
import { ParentItinerary, useReferenceCropItineraries } from '../../useCropItineraries';
import { useOutletsCommands, useOutletsState } from '../state-context';
import { HeaderCellMenuButton } from './HeaderCellMenu';

const Modal = styled(RightFormModal)`
  background-color: ${colorStyle.cta2['6']};
`;
const Table = styled.table`
  width: 100%;
  background-color: ${colorStyle.greys['9-white']};
  border-collapse: collapse;
  border-spacing: 0;

  td,
  th {
    height: 50px;
    padding: 0px 4px;
    border: 1px solid ${colorStyle.greys['7']};
    text-align: left;
    ${getTextStyle(14, 'regular')}
    color: ${colorStyle.greys['1-black']};
  }
`;

export interface ProductSelection {
  parentCropItineraryId: string;
  referenceParentProduct?: ParentProduct;
  referenceParentItinerary?: ParentItinerary;
  isSelected: boolean;
  name: string;
  quantityUnit: HarvestUnitEnum;
}

export interface SelectProductsModalProps {
  setShowSelectProductsModal: Setter<boolean>;
}

export function SelectProductsModal({ setShowSelectProductsModal }: SelectProductsModalProps) {
  const { t } = useTranslation();

  const { cropItineraries, error, loading } = useReferenceCropItineraries();

  const onClose = () => setShowSelectProductsModal(false);

  return loading || error ? (
    <Modal
      title={t('outlet:select-products-modal.title')}
      onCancel={onClose}
      onConfirm={onClose}
      loading
    >
      <Spinner size={32} />
    </Modal>
  ) : (
    <SelectProductsModalInner
      cropItineraries={cropItineraries}
      onClose={onClose}
    />
  );
}

function SelectProductsModalInner({
  onClose,
  cropItineraries,
}: {
  onClose: () => void;
  cropItineraries: ParentItinerary[];
}) {
  const { t } = useTranslation();
  const { selectedParentProducts, otherPossibleParentProducts, time, farmingSystem } = useOutletsState();
  const applyCommand = useOutletsCommands();

  const [selectedProducts, setSelectedProducts] = useState<ProductSelection[]>(() => {
    const { allPossibleParentProducts: refParentProducts, parentItinerariesById: refParentItinerariesById } =
      buildAllPossibleParentProducts({
        parentItineraries: cropItineraries,
        time,
        farmingSystem,
      });

    const selectedParentValues = Object.values(selectedParentProducts);
    const otherParentValues = Object.values(otherPossibleParentProducts);
    const possibleProductsByRefId = Object.fromEntries(
      [...selectedParentValues, ...otherParentValues]
        .map((prod) =>
          prod.isReferenceItinerary
            ? [prod.parentCropItineraryId, prod]
            : prod.referenceItineraryId
            ? [prod.referenceItineraryId, prod]
            : null,
        )
        .filter(identity),
    );
    const unusedReferenceProducts = Object.values(refParentProducts).filter(
      (refParentProduct) => !possibleProductsByRefId[refParentProduct.parentCropItineraryId],
    );
    const products: ProductSelection[] = [
      ...selectedParentValues.map((product) => ({
        parentCropItineraryId: product.parentCropItineraryId,
        isSelected: true,
        name: product.name,
        quantityUnit: product.quantityUnit,
      })),
      ...otherParentValues.map((product) => ({
        parentCropItineraryId: product.parentCropItineraryId,
        isSelected: false,
        name: product.name,
        quantityUnit: product.quantityUnit,
      })),
      ...unusedReferenceProducts.map((product) => ({
        referenceParentProduct: product,
        referenceParentItinerary: refParentItinerariesById[product.parentCropItineraryId],
        parentCropItineraryId: product.parentCropItineraryId,
        isSelected: false,
        name: product.name,
        quantityUnit: product.quantityUnit,
      })),
    ];
    return sortBy(products, ({ name }) => name);
  });

  const toggleProductSelected = (editedProduct: ProductSelection) =>
    setSelectedProducts((selectedProducts) =>
      selectedProducts.map((product) =>
        product.parentCropItineraryId === editedProduct.parentCropItineraryId
          ? {
              ...product,
              isSelected: !product.isSelected,
            }
          : product,
      ),
    );

  const onSubmit = () => {
    applyCommand({
      type: 'setSelectedProducts',
      selectedProducts,
    });
    onClose();
  };

  const [editedProduct, setEditedProduct] = useState<ProductSelection>();
  const closeEditProductModal = () => setEditedProduct(null);
  const onConfirmEditProductModal = (product: ProductSelection) => {
    setSelectedProducts((selectedProducts) => {
      return selectedProducts.map((otherProduct) => {
        if (otherProduct.parentCropItineraryId === product.parentCropItineraryId) {
          return product;
        } else {
          return otherProduct;
        }
      });
    });
  };

  return (
    <Modal
      title={t('outlet:select-products-modal.title')}
      onCancel={() => {
        if (!editedProduct) {
          onClose();
        }
      }}
      onConfirm={onSubmit}
    >
      <Table>
        <thead>
          <tr>
            <th>{t('outlet:select-products-modal.headers.products')}</th>
            <th>{t('outlet:select-products-modal.headers.unit')}</th>
          </tr>
        </thead>
        <tbody>
          {selectedProducts.map((product) => (
            <tr key={product.parentCropItineraryId}>
              <td>
                <StyledCellContent>
                  <Checkbox
                    value={product.isSelected}
                    onValueChange={() => toggleProductSelected(product)}
                    label={product.name}
                  />
                  <HeaderCellMenuButton>
                    <div onClick={() => setEditedProduct(product)}>
                      {t('outlet:select-products-modal.product-menu.rename')}
                    </div>
                  </HeaderCellMenuButton>
                </StyledCellContent>
              </td>
              <td>{translateHarvestUnitEnum(t, product.quantityUnit, 'long')}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {editedProduct && (
        <EditProductModal
          editedProduct={editedProduct}
          selectedProducts={selectedProducts}
          onConfirm={onConfirmEditProductModal}
          onClose={closeEditProductModal}
        />
      )}
    </Modal>
  );
}

const StyledCellContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

function EditProductModal({
  editedProduct,
  selectedProducts,
  onClose,
  onConfirm,
}: {
  onConfirm: (editedProduct: ProductSelection) => void;
  onClose: () => void;
  editedProduct: ProductSelection;
  selectedProducts: ProductSelection[];
}) {
  const { t } = useTranslation();

  const otherNames = useMemo(
    () =>
      selectedProducts
        .filter((p) => p.parentCropItineraryId !== editedProduct.parentCropItineraryId)
        .map((p) => p.name),
    [editedProduct.parentCropItineraryId, selectedProducts],
  );

  const productSchema = yup.object({
    name: yup.string().required().min(1).notOneOf(otherNames), //.required(t('project:edit.form.fields.projectTitle.error.required')),
  });
  type ProductForm = yup.InferType<typeof productSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProductForm>({
    resolver: yupResolver(productSchema),
    defaultValues: {
      name: editedProduct.name,
    },
  });

  return (
    <Modal
      title={t('outlet:select-products-modal.edit-product-modal.title')}
      onClose={onClose}
      onCancel={onClose}
      onConfirm={handleSubmit((form) => {
        if (editedProduct.name !== form.name) {
          onConfirm({
            ...editedProduct,
            name: form.name,
          });
        }
        onClose();
      })}
    >
      <HookedTextInput
        required
        fieldName="name"
        register={register}
        errors={errors}
      />
      <ErrorMessage message={errors.name?.message} />
    </Modal>
  );
}
