import { useTranslation } from '@elzeard/common-components';
import React, { useMemo } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { routes } from '../../app/routes';
import { ConfirmationModal } from '../../common/components/modal/ConfirmationModal';
import { MyProjectsQuery, useMeQuery, useRemovePepiniereProjectMutation } from '../../common/generated/graphql';
import { MyProjects } from '../../graphql/projects';

export function ModalRemoveProject() {
  const { t } = useTranslation();
  const history = useHistory();

  const { farmId: farmIdSuffix } = useParams<{ farmId: string }>();
  const fullFarmId = farmIdSuffix && `farm/${farmIdSuffix}`;

  const { data: meQueryData, loading, error } = useMeQuery();

  const projectName = useMemo(() => {
    if (loading || error) {
      return '';
    }
    const farms =
      meQueryData.me2.__typename === 'User_Producer' ? meQueryData.me2.worksFor.edges.map((edge) => edge.node) : [];
    return farms.find((farm) => farm.id === fullFarmId)?.name;
  }, [loading, error, meQueryData, fullFarmId]);

  const onCancel = () => history.push(generatePath(routes.project.selected, { farmId: farmIdSuffix }));

  const [removeProject, { loading: mutationLoading }] = useRemovePepiniereProjectMutation({
    onCompleted: () => {
      history.push(routes.project.list);
    },
    update: (cache) => {
      const data = cache.readQuery<MyProjectsQuery>({
        query: MyProjects,
      });
      if (data) {
        cache.writeQuery<MyProjectsQuery>({
          query: MyProjects,
          data: {
            ...data,
            farmsForPepiniere: data.farmsForPepiniere.filter((farm) => farm.id !== fullFarmId),
          },
        });
      }
    },
  });

  return (
    <ConfirmationModal
      title={t('project:removal.title')}
      details={t('project:removal.details', { project: projectName })}
      mainActionLabelKey="common:button.remove"
      onConfirm={() => {
        removeProject({
          variables: {
            farmId: fullFarmId,
          },
        });
      }}
      onCancel={onCancel}
      loading={mutationLoading}
    />
  );
}
