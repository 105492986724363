// eslint-disable-next-line import/no-anonymous-default-export
export default {
  // TODO move to project.js
  alertUnsavedChanges: "Vous n'avez pas sauvegardé votre projet. Voulez-vous quitter la page ?",
  home: {
    topbar: {
      back: 'Retour',
      duration: 'Durée des cultures',
      quantity: 'Surfaces et quantités',
      'auto-compute-needs': 'Calcul auto des quantités',
    },
    // TODO move to project.js
    spinner: 'Chargement des données de planification',
  },

  'confirmation-modal': {
    'delete-serie': {
      title: 'Êtes-vous sûr de vouloir supprimer cette série ?',
      details: 'Le numéro des séries sera renommé par ordre chronologique d’implantation.',
    },
  },
  'header-table': {
    title: 'Séries',
    'no-selection': {
      head: {
        cultureMode: { label: 'Mode de culture', unit: '', tooltip: 'Mode de culture' },
        quantity: { label: 'Quantité', unit: '', tooltip: 'Quantité totale à récolter' },
        harvestQuantityUnit: { label: 'Unité', unit: '', tooltip: 'Unité de récolte' },
      },
      'outlets-needs': 'Besoins de la gamme',
    },
    'product-selected': {
      head: {
        // cultureMode: { label: 'mode', unit: '', tooltip: 'Mode de culture' },
        implantationWeek: { label: 'Implant.', unit: 'n° sem', tooltip: "Semaine d'implantion" },
        maturationWeeks: { label: 'Culture', unit: 'nb sem', tooltip: 'Durée de maturation' },
        harvestWeeks: { label: 'Récolte', unit: 'nb sem', tooltip: 'Durée de récolte' },
        storageWeeks: { label: 'Conserv.', unit: 'nb sem', tooltip: 'Durée de conservation' },
        expectedYield: { label: 'Rend.', unit: 'u/m²', tooltip: 'Rendement attendu en unité de récolte/m²' }, // TODO use the harvestQuantityUnit in the unit
        lossMargin: { label: 'Marge', unit: '%', tooltip: 'Taux de perte attendu' },
        surface: { label: 'Surf', unit: 'm²', tooltip: 'Surface nécessaire' },
        bedLength: { label: 'L.planch', unit: 'm', tooltip: 'Longueur de planche' },
        quantity: { label: 'Qté totale', unit: '', tooltip: 'Quantité totale à récolter' },
        // harvestQuantityUnit: { label: "", unit: "", tooltip: "",},
      },
    },
    'purchase-resale': 'Achat-revente',
    'serie-sub-row': {
      'name-cell': {
        serie: 'Série',
      },
      'yield-cell': {
        min: 'Bas',
        max: 'Haut',
        value: 'Moyen',
      },
    },
    'serie-menu': {
      duplicate: 'Dupliquer la série',
      delete: 'Supprimer la série',
      rename: 'Renommer la série',
    },
    values: {
      'culture-mode': {
        Mixed: 'Mixte',
        PurchaseResale: 'Achat-revente',
      },
    },
  },
};
