import { buildWeek, getWeek, MonthInterval, ToHandlers } from '@elzeard/common-components';
import {
  computeNeededSurface,
  computeNumberMapTotal,
  getSerieEnd,
  getStorageBegin,
  getStorageEnd,
  getWeeksRecord,
  negateNumberMap,
  NumberMap,
  sumNumberMaps,
} from '@elzeard/common-planning';
import { addDays, addWeeks, differenceInDays, differenceInWeeks, isWithinInterval } from 'date-fns';
import { last } from 'lodash';
import { buildAvailabilityPeriod } from '../common/ProductAvailability';
import { getWeekKey, weekKeyToWeek } from '../outlet/utils';
import { ChildProduct, ParentProduct, PepiniereSerie, ProjectPageState, PurchaseResaleChild } from '../state';
import { buildNewSerie, getMatchingWeek } from '../state-init';
import { SeriesCommands } from './commands';
import { SerieHeaderColumnName } from './components/SeriesHeaderTable';
import { buildProductSerie, updateSeriesState } from './state-build';
import { EditedNeedCellCoordinates, EditedPropertyCellCoordinates } from './state-displayOptions';
import { ProductSerie, SeriesPageState, SeriesProduct } from './state-full';

function buildFullState(state: ProjectPageState<SeriesPageState>) {
  return {
    ...state,
    ...updateSeriesState(state),
  };
}

export const seriesCommandHandler: ToHandlers<ProjectPageState<SeriesPageState>, SeriesCommands> = {
  toggleExpandSeriesRow(previousState, command) {
    const previousValue = previousState.expandedSeriesRows[command.rowId] || false;
    return {
      ...previousState,
      expandedSeriesRows: {
        ...previousState.expandedSeriesRows,
        [command.rowId]: !previousValue,
      },
    };
  },
  setEditedSeriesCell(previousState, command) {
    const { type, ...typedEditedCell } = command;
    const editedCell = typedEditedCell as EditedNeedCellCoordinates & EditedPropertyCellCoordinates;
    const previousEditedCell = previousState.editedSerieCell as EditedNeedCellCoordinates &
      EditedPropertyCellCoordinates;
    if (
      (!editedCell && !previousState.editedSerieCell) ||
      (editedCell &&
        previousEditedCell &&
        editedCell.serieRowId === previousEditedCell.serieRowId &&
        editedCell.parentItineraryId === previousEditedCell.parentItineraryId &&
        editedCell.editedProperty === previousEditedCell.editedProperty &&
        editedCell.weekKey === previousEditedCell.weekKey)
    ) {
      return previousState;
    }
    return {
      ...previousState,
      editedSerieCell: editedCell.parentItineraryId ? typedEditedCell : null,
    };
  },
  askSeriesCommandConfirmation(previousState, { command, title, details }) {
    return {
      ...previousState,
      seriesCommandConfirmationModal: command ? { command, title, details } : null,
    };
  },
  editProductSeries(previousState, command) {
    return buildFullState({
      ...previousState,
      editedSeriesProductRowId: command.editedSeriesProductRowId,
      displayedColumns: previousState.displayedColumns || 'duration',
    });
  },
  displayColumns(previousState, command) {
    return {
      ...previousState,
      displayedColumns: command.displayedColumns,
    };
  },
  toggleDisplayOutletNeeds(previousState, command) {
    return {
      ...previousState,
      displayOutletNeeds: !previousState.displayOutletNeeds,
    };
  },
  toggleDisplayAllProducts(previousState, command) {
    return buildFullState({
      ...previousState,
      displayAllProducts: !previousState.displayAllProducts,
    });
  },
  toggleDisplayPurchaseResale(previousState, command) {
    return {
      ...previousState,
      displayPurchaseResale: !previousState.displayPurchaseResale,
    };
  },
  editSerieNeed(previousState, command) {
    const {
      parentItineraryId,
      // TODO remove from command ?
      // serieRowId,
      productRowId,
      childItineraryId,
      value,
      weekKey,
      needIndex,
    } = command;
    const { selectedParentProducts } = previousState;
    const intialParentProduct = selectedParentProducts[parentItineraryId];

    let initialChildProduct = intialParentProduct.selectedChildrenByRowId[productRowId];
    const updatedOtherPossibleChildrenByChildItineraryId = initialChildProduct
      ? intialParentProduct.otherPossibleChildrenByChildItineraryId
      : { ...intialParentProduct.otherPossibleChildrenByChildItineraryId };
    if (!initialChildProduct) {
      initialChildProduct = updatedOtherPossibleChildrenByChildItineraryId[childItineraryId];
      delete updatedOtherPossibleChildrenByChildItineraryId[childItineraryId];
    }

    return buildFullState({
      ...previousState,
      selectedParentProducts: {
        ...selectedParentProducts,
        [parentItineraryId]: {
          ...intialParentProduct,
          isUpdated: true,
          selectedChildrenByRowId: {
            ...intialParentProduct.selectedChildrenByRowId,
            [productRowId]: editSerieNeed({
              childProduct: initialChildProduct,
              value,
              weekKey,
              needIndex,
            }),
          },
          otherPossibleChildrenByChildItineraryId: updatedOtherPossibleChildrenByChildItineraryId,
        },
      },
      actions: [...previousState.actions, command],
    });
  },
  editSerieProperty(previousState, command) {
    const {
      // TODO remove from command ?
      // serieRowId,
      parentItineraryId,
      childItineraryId,
      productRowId,
      value,
      propertyName,
    } = command;
    const { selectedParentProducts, time } = previousState;
    const intialParentProduct = selectedParentProducts[parentItineraryId];
    const editor = seriePropertyEditors[propertyName];
    if (editor) {
      let initialChildProduct = intialParentProduct.selectedChildrenByRowId[productRowId];
      const updatedOtherPossibleChildrenByChildItineraryId = initialChildProduct
        ? intialParentProduct.otherPossibleChildrenByChildItineraryId
        : { ...intialParentProduct.otherPossibleChildrenByChildItineraryId };
      if (!initialChildProduct) {
        initialChildProduct = updatedOtherPossibleChildrenByChildItineraryId[childItineraryId];
        delete updatedOtherPossibleChildrenByChildItineraryId[childItineraryId];
      }
      return buildFullState({
        ...previousState,
        selectedParentProducts: {
          ...selectedParentProducts,
          [parentItineraryId]: {
            ...intialParentProduct,
            isUpdated: true,
            selectedChildrenByRowId: {
              ...intialParentProduct.selectedChildrenByRowId,
              [productRowId]: editor({ initialProduct: initialChildProduct, value, time }),
            },
            otherPossibleChildrenByChildItineraryId: updatedOtherPossibleChildrenByChildItineraryId,
          },
        },
        actions: [...previousState.actions, command],
      });
    } else {
      console.warn('could not edit serie property', propertyName);
      return previousState;
    }
  },
  renameSerie(previousState, command) {
    const { parentItineraryId, productRowId, childItineraryId, newName } = command;
    const { selectedParentProducts, parentItinerariesById } = previousState;

    const intialParentProduct = selectedParentProducts[parentItineraryId];
    let initialChildProduct = intialParentProduct.selectedChildrenByRowId[productRowId];
    const updatedOtherPossibleChildrenByChildItineraryId = initialChildProduct
      ? intialParentProduct.otherPossibleChildrenByChildItineraryId
      : { ...intialParentProduct.otherPossibleChildrenByChildItineraryId };
    if (!initialChildProduct) {
      initialChildProduct = updatedOtherPossibleChildrenByChildItineraryId[childItineraryId];
      delete updatedOtherPossibleChildrenByChildItineraryId[childItineraryId];
    }

    const customName = newName || null;
    if (initialChildProduct.customName === customName) {
      return previousState;
    }

    const parentItinerary = parentItinerariesById[parentItineraryId];
    const childItineraryIndex = parentItinerary.children.findIndex((child) => child.id === childItineraryId);
    const updatedChildItineraries = [...parentItinerary.children];
    updatedChildItineraries.splice(childItineraryIndex, 1, {
      ...updatedChildItineraries[childItineraryIndex],
      isUpdated: true,
      name: customName,
    });

    return buildFullState({
      ...previousState,
      selectedParentProducts: {
        ...selectedParentProducts,
        [parentItineraryId]: {
          ...intialParentProduct,
          isUpdated: true,
          selectedChildrenByRowId: {
            ...intialParentProduct.selectedChildrenByRowId,
            [productRowId]: {
              ...initialChildProduct,
              customName,
              isUpdated: true,
            },
          },
          otherPossibleChildrenByChildItineraryId: updatedOtherPossibleChildrenByChildItineraryId,
        },
      },
      parentItinerariesById: {
        ...parentItinerariesById,
        [parentItineraryId]: {
          ...parentItinerary,
          isUpdated: true,
          children: updatedChildItineraries,
        },
      },
      actions: [...previousState.actions, command],
    });
  },
  editPurchaseResaleNeed(previousState, command) {
    const { parentItineraryId, value, weekKey } = command;
    const { actions, selectedParentProducts } = previousState;

    const intialParentProduct = selectedParentProducts[parentItineraryId];
    const initialPurchaseResale: PurchaseResaleChild = intialParentProduct.purchaseResale || {
      isToBeDeleted: false,
      isUpdated: true,
      parentCropItineraryId: parentItineraryId,
      productId: null,
      productionNeedId: null,
      weeklyNeeds: {},
    };
    if ((value || 0) === (initialPurchaseResale.weeklyNeeds[weekKey] || 0)) {
      return previousState;
    }
    const updatedPurchaseResale: PurchaseResaleChild = {
      ...initialPurchaseResale,
      isToBeDeleted: false,
      isUpdated: true,
      weeklyNeeds: {
        ...initialPurchaseResale.weeklyNeeds,
        [weekKey]: value || null,
      },
    };
    const total = computeNumberMapTotal(updatedPurchaseResale.weeklyNeeds);
    return buildFullState({
      ...previousState,
      selectedParentProducts: {
        ...selectedParentProducts,
        [parentItineraryId]: {
          ...intialParentProduct,
          isUpdated: true,
          purchaseResale: total
            ? updatedPurchaseResale
            : initialPurchaseResale.productId
            ? {
                ...initialPurchaseResale,
                isToBeDeleted: true,
              }
            : null,
        },
      },
      actions: [...actions, command],
    });
  },
  autoComputeSeriesNeeds(previousState, command) {
    const { editedSeriesProductRowId, seriesProducts, selectedParentProducts, actions } = previousState;
    if (!editedSeriesProductRowId) {
      console.warn('WTF?');
      return previousState;
    }

    const editedSeriesProduct = seriesProducts[0];
    const initialProjectProduct = selectedParentProducts[editedSeriesProduct.parentCropItineraryId];
    const needsToDispatch = sumNumberMaps(
      editedSeriesProduct.weeklyNeedsFromAllOutlets,
      negateNumberMap(initialProjectProduct.purchaseResale?.weeklyNeeds || {}),
    );
    const updatedProjectProduct: ParentProduct = Object.entries(needsToDispatch).reduce(
      (updatedProjectProduct, [weekKey, outletsNeeds]) => {
        if (!outletsNeeds || outletsNeeds < 0) {
          return updatedProjectProduct;
        }
        const matchingChildren = editedSeriesProduct.series
          .filter((serie) => serie.harvestWeeks[weekKey] || serie.storageWeeks[weekKey])
          .map((serie) => updatedProjectProduct.selectedChildrenByRowId[serie.productRowId]);
        const { updatedChildren } = matchingChildren.reduce(
          ({ updatedChildren, remainingNeedsToDispatch, needsToDispatchByChild }, childProduct, productIndex) => {
            const isLastMatchingProduct = productIndex === matchingChildren.length - 1;
            const dispatchedNeeds = isLastMatchingProduct ? remainingNeedsToDispatch : needsToDispatchByChild;

            updatedChildren.push([
              childProduct.rowId,
              editSerieNeed({
                childProduct,
                value: dispatchedNeeds,
                weekKey,
                // needIndex
              }),
            ]);
            return {
              updatedChildren,
              remainingNeedsToDispatch: remainingNeedsToDispatch - dispatchedNeeds,
              needsToDispatchByChild,
            };
          },
          {
            updatedChildren: [] as [string, ChildProduct][],
            remainingNeedsToDispatch: outletsNeeds,
            needsToDispatchByChild: Math.ceil(outletsNeeds / matchingChildren.length),
          },
        );
        updatedProjectProduct.selectedChildrenByRowId = {
          ...updatedProjectProduct.selectedChildrenByRowId,
          ...Object.fromEntries(updatedChildren),
        };
        return updatedProjectProduct;
      },
      {
        ...initialProjectProduct,
        isUpdated: true,
        selectedChildrenByRowId: {
          ...initialProjectProduct.selectedChildrenByRowId,
        },
      } as ParentProduct,
    );

    return buildFullState({
      ...previousState,
      selectedParentProducts: {
        ...selectedParentProducts,
        [updatedProjectProduct.parentCropItineraryId]: updatedProjectProduct,
      },
      actions: [...actions, command],
    });
  },
  removeSerie(previousState, command) {
    const { parentItineraryId, productRowId } = command;
    const { actions, selectedParentProducts } = previousState;

    const initialProjectProduct = selectedParentProducts[parentItineraryId];
    const childProductToRemove = initialProjectProduct.selectedChildrenByRowId[productRowId];

    if (!childProductToRemove) {
      console.log('Cannot unselect a child product that is not selected yet', command);
      return previousState;
    }
    const updatedProjectProduct: ParentProduct = {
      ...initialProjectProduct,
      isUpdated: true,
      selectedChildrenByRowId: { ...initialProjectProduct.selectedChildrenByRowId },
      otherPossibleChildrenByChildItineraryId: { ...initialProjectProduct.otherPossibleChildrenByChildItineraryId },
    };
    const updatedChildProduct: ChildProduct = {
      ...childProductToRemove,
      isUpdated: true,
      series: childProductToRemove.itinerarySeries,
    };
    if (childProductToRemove.childCropItineraryId) {
      delete updatedProjectProduct.selectedChildrenByRowId[productRowId];
      updatedProjectProduct.otherPossibleChildrenByChildItineraryId[childProductToRemove.childCropItineraryId] =
        updatedChildProduct;
    } else if (!childProductToRemove.productId) {
      delete updatedProjectProduct.selectedChildrenByRowId[productRowId];
    } else {
      updatedProjectProduct.selectedChildrenByRowId[productRowId] = updatedChildProduct;
    }

    return buildFullState({
      ...previousState,
      selectedParentProducts: {
        ...selectedParentProducts,
        [updatedProjectProduct.parentCropItineraryId]: updatedProjectProduct,
      },
      actions: [...actions, command],
    });
  },
  duplicateSerie(previousState, command) {
    const { parentItineraryId, productRowId } = command;
    const { actions, selectedParentProducts, seriesProducts } = previousState;

    const initialProjectProduct = selectedParentProducts[parentItineraryId];
    const childProductToDuplicate = initialProjectProduct.selectedChildrenByRowId[productRowId];

    if (!childProductToDuplicate) {
      console.log('Cannot duplicate a child product that is not selected yet', command);
      return previousState;
    }

    // update global state
    const updatedProjectProduct: ParentProduct = {
      ...initialProjectProduct,
      isUpdated: true,
      selectedChildrenByRowId: { ...initialProjectProduct.selectedChildrenByRowId },
    };
    const newChildProduct: ChildProduct = {
      ...childProductToDuplicate,
      isUpdated: true,
      series: childProductToDuplicate.series,
      productId: null,
      itinerarySeries: null,
      childCropItineraryId: null,
      serieToRemoveId: null,
      rowId: `z${childProductToDuplicate.rowId}`, // to be sorted after childProductToDuplicate
    };
    updatedProjectProduct.selectedChildrenByRowId[newChildProduct.rowId] = newChildProduct;

    // update series page state
    const seriesProductIndex = seriesProducts.findIndex(
      (product) => product.parentCropItineraryId === parentItineraryId,
    );
    const initialSeriesProduct = seriesProducts[seriesProductIndex];
    const initialSerieIndex = initialSeriesProduct.series.findIndex((serie) => serie.productRowId === productRowId);
    const updatedSeries = [...initialSeriesProduct.series];
    const newSerie = buildProductSerie(newChildProduct, true);
    updatedSeries.splice(initialSerieIndex + 1, 0, newSerie);
    const updatedSeriesProduct: SeriesProduct = {
      ...initialSeriesProduct,
      series: updatedSeries,
      quantity: initialSeriesProduct.quantity + newSerie.quantity,
      weeklyNeedsFromAllSeries: sumNumberMaps(initialSeriesProduct.weeklyNeedsFromAllSeries, newSerie.needs),
    };
    const updatedProducts = [...seriesProducts];
    updatedProducts[seriesProductIndex] = updatedSeriesProduct;

    return {
      ...previousState,
      selectedParentProducts: {
        ...selectedParentProducts,
        [updatedProjectProduct.parentCropItineraryId]: updatedProjectProduct,
      },
      seriesProducts: updatedProducts,
      actions: [...actions, command],
    };
  },
};

function editSerieNeed({
  childProduct,
  value,
  weekKey,
  needIndex = (() => {
    if (childProduct.series.length === 0) {
      return -1;
    }
    const serie1 = childProduct.series[0];
    const inSerie1 = serie1.harvestWeeks[weekKey] || serie1.storageWeeks[weekKey];
    if (childProduct.series.length === 1) {
      return inSerie1 ? 0 : -1;
    }
    const serie2 = childProduct.series[1];
    const inSerie2 = serie2.harvestWeeks[weekKey] || serie2.storageWeeks[weekKey];
    if (inSerie1 && inSerie2) {
      return 2;
    } else if (inSerie1) {
      return 0;
    } else if (inSerie2) {
      return 1;
    } else {
      return -1;
    }
  })(),
}: {
  childProduct: ChildProduct;
  value: number;
  weekKey: string;
  needIndex?: number;
}): ChildProduct {
  let actualValue = value || 0;

  const initialSeries = childProduct.series;
  const updatedSeries = [...initialSeries];

  const difference = (actualValue || 0) - (initialSeries[needIndex === 1 ? 1 : 0].needs[weekKey] || 0);
  if (difference === 0) {
    return childProduct;
  }

  const updateSerie = (serieIndex: number, weekKey: string) => {
    const initialSerie = initialSeries[serieIndex];
    const expectedVolume = (initialSerie.expectedVolume || 0) + difference;
    const computedSurfaceNeeds = computeNeededSurface({
      expectedLostRate: initialSerie.expectedLostRate,
      expectedYield: initialSerie.expectedYield,
      volume: expectedVolume,
    });
    updatedSeries[serieIndex] = {
      ...initialSerie,
      isUpdated: true,
      needs: {
        ...initialSerie.needs,
        [weekKey]: actualValue,
      },
      expectedVolume,
      computedSurfaceNeeds,
    };
  };

  if (needIndex === 2) {
    // we are editing a week where the 2 series are overlapping
    actualValue = actualValue / 2;
    const week = weekKeyToWeek(weekKey);
    const previousYearWeek = getMatchingWeek(week, false);
    const nextYearWeek = getMatchingWeek(week, true);
    updateSerie(0, weekKey);
    updateSerie(0, getWeekKey(nextYearWeek));
    updateSerie(1, weekKey);
    updateSerie(1, getWeekKey(previousYearWeek));
  } else if (initialSeries.length === 2) {
    const week = weekKeyToWeek(weekKey);
    const matchingWeek = getMatchingWeek(week, needIndex === 0);
    updateSerie(needIndex, weekKey);
    updateSerie(needIndex === 0 ? 1 : 0, getWeekKey(matchingWeek));
  } else {
    updateSerie(needIndex, weekKey);
  }

  return {
    ...childProduct,
    isUpdated: true,
    series: updatedSeries,
  };
}

const seriePropertyEditors: {
  [P in SerieHeaderColumnName]: (arg: {
    initialProduct: ChildProduct;
    value: ProductSerie[P];
    time: MonthInterval;
  }) => ChildProduct;
} = {
  bedLength: null,
  harvestQuantityUnit: null,
  quantity: null,
  cultureMode({ initialProduct, value }) {
    return {
      ...initialProduct,
      cultureMode: value,
      isUpdated: true,
    };
  },
  expectedYield({ initialProduct, value }) {
    return {
      ...initialProduct,
      isUpdated: true,
      series: initialProduct.series.map((serie) => {
        const updatedSerie: PepiniereSerie = {
          ...serie,
          isUpdated: true,
          expectedYield: value,
        };
        return {
          ...updatedSerie,
          computedSurfaceNeeds: computeNeededSurface({
            expectedLostRate: updatedSerie.expectedLostRate,
            expectedYield: updatedSerie.expectedYield,
            volume: updatedSerie.expectedVolume,
          }),
        };
      }),
    };
  },
  lossMargin({ initialProduct, value }) {
    return {
      ...initialProduct,
      isUpdated: true,
      series: initialProduct.series.map((serie) => {
        const updatedSerie: PepiniereSerie = {
          ...serie,
          isUpdated: true,
          expectedLostRate: value,
        };
        return {
          ...updatedSerie,
          computedSurfaceNeeds: computeNeededSurface({
            expectedLostRate: updatedSerie.expectedLostRate,
            expectedYield: updatedSerie.expectedYield,
            volume: updatedSerie.expectedVolume,
          }),
        };
      }),
    };
  },
  surface({ initialProduct, value, time }) {
    return {
      ...initialProduct,
      isUpdated: true,
      series: initialProduct.series.map((serie) => ({
        ...serie,
        isUpdated: true,
        editedSurfaceNeeds: value,
      })),
    };
  },
  implantationWeek({ initialProduct, value, time }) {
    // change to be applied to the harvest dates and the productionNeeds
    const delta = differenceInWeeks(value.firstDay, initialProduct.series[0].begin.firstDay);

    const { series, harvestPeriods, storagePeriods } = initialProduct.series.reduce(
      (acc, serie, serieIndex) => {
        const harvest = {
          begin: getWeek(addWeeks(serie.harvest.begin.firstDay, delta)),
          end: getWeek(addWeeks(serie.harvest.end.firstDay, delta)),
        };
        const storage = serie.storageDays && {
          begin: getStorageBegin(harvest.end, serie.storageDays),
          end: getStorageEnd(harvest.end, serie.storageDays),
        };
        const harvestWeeks = getWeeksRecord(harvest.begin, harvest.end);
        const storageWeeks = storage ? getWeeksRecord(storage.begin, storage.end) : {};
        acc.series.push({
          ...serie,
          isUpdated: true,
          begin: serieIndex === 0 ? value : getWeek(addWeeks(serie.begin.firstDay, delta)),
          harvest,
          needs: shiftNeeds(serie.needs, delta),
          harvestWeeks,
          storageWeeks,
        });
        acc.harvestPeriods.push({
          ...harvest,
          weeks: harvestWeeks,
        });
        acc.storagePeriods.push(
          storage
            ? {
                ...storage,
                weeks: storageWeeks,
              }
            : null,
        );
        return acc;
      },
      {
        series: [],
        harvestPeriods: [],
        storagePeriods: [],
      } as Pick<ChildProduct, 'series' | 'harvestPeriods' | 'storagePeriods'>,
    );

    return addOrRemoveSeriesOverlappingProjectPeriod(
      {
        ...initialProduct,
        isUpdated: true,
        series,
        harvestPeriods,
        storagePeriods,
      },
      time,
    );
  },
  maturationDuration({ initialProduct, value, time }) {
    if (!value || isNaN(value) || value < 1) {
      console.log('Invalid maturation duration, update ignored', value);
      return initialProduct;
    }
    const initialValue = initialProduct.series[0].matureDays;
    const matureDays = value * 7;
    const daysAdded = matureDays - initialValue;

    const { series, harvestPeriods, storagePeriods } = initialProduct.series.reduce(
      (acc, serie, serieIndex) => {
        const harvest = {
          begin: getWeek(addDays(serie.harvest.begin.firstDay, daysAdded)),
          end: getWeek(addDays(serie.harvest.end.firstDay, daysAdded)),
        };
        const storage = serie.storageDays && {
          begin: getStorageBegin(harvest.end, serie.storageDays),
          end: getStorageEnd(harvest.end, serie.storageDays),
        };
        const harvestWeeks = getWeeksRecord(harvest.begin, harvest.end);
        const storageWeeks = storage ? getWeeksRecord(storage.begin, storage.end) : {};
        acc.series.push({
          ...serie,
          isUpdated: true,
          matureDays,
          harvest,
          harvestWeeks,
          storageWeeks,
          needs: shiftNeeds(serie.needs, daysAdded / 7),
        });
        acc.harvestPeriods.push({
          ...harvest,
          weeks: harvestWeeks,
        });
        acc.storagePeriods.push(
          storage
            ? {
                ...storage,
                weeks: storageWeeks,
              }
            : null,
        );
        return acc;
      },
      {
        series: [],
        harvestPeriods: [],
        storagePeriods: [],
      } as Pick<ChildProduct, 'series' | 'harvestPeriods' | 'storagePeriods'>,
    );

    return addOrRemoveSeriesOverlappingProjectPeriod(
      {
        ...initialProduct,
        isUpdated: true,
        series,
        harvestPeriods,
        storagePeriods,
      },
      time,
    );
  },
  harvestDuration({ initialProduct, value, time }) {
    if (!value || isNaN(value) || value < 1) {
      console.log('Invalid harvest duration, update ignored', value);
      return initialProduct;
    }
    const initialValue = initialProduct.series[0].harvestDays;
    const updatedValue = value * 7;

    const { series, harvestPeriods, storagePeriods } = initialProduct.series.reduce(
      (acc, serie) => {
        const harvest = {
          begin: serie.harvest.begin,
          end: getWeek(addDays(serie.harvest.begin.firstDay, updatedValue - 1)),
        };

        const storage = serie.storageDays && {
          begin: getStorageBegin(harvest.end, serie.storageDays),
          end: getStorageEnd(harvest.end, serie.storageDays),
        };
        const harvestWeeks = getWeeksRecord(harvest.begin, harvest.end);
        const storageWeeks = storage ? getWeeksRecord(storage.begin, storage.end) : {};

        let computedSurfaceNeeds = serie.computedSurfaceNeeds;
        let expectedVolume = serie.expectedVolume;
        let needs = serie.needs;
        if (initialValue > updatedValue) {
          const [updatedNeeds, removedTotal] = removeNeedsOutsidePeriods(serie.needs, harvestWeeks, storageWeeks);
          if (removedTotal) {
            needs = updatedNeeds;
            expectedVolume -= removedTotal;
            computedSurfaceNeeds = computeNeededSurface({
              expectedLostRate: serie.expectedLostRate,
              expectedYield: serie.expectedYield,
              volume: expectedVolume,
            });
          }
        }
        acc.series.push({
          ...serie,
          isUpdated: true,
          harvestDays: updatedValue,
          harvest,
          harvestWeeks,
          storageWeeks,
          computedSurfaceNeeds,
          expectedVolume,
          needs,
        });
        acc.harvestPeriods.push({
          ...harvest,
          weeks: harvestWeeks,
        });
        acc.storagePeriods.push(
          storage
            ? {
                ...storage,
                weeks: storageWeeks,
              }
            : null,
        );
        return acc;
      },
      {
        series: [],
        harvestPeriods: [],
        storagePeriods: [],
      } as Pick<ChildProduct, 'series' | 'harvestPeriods' | 'storagePeriods'>,
    );

    return addOrRemoveSeriesOverlappingProjectPeriod(
      {
        ...initialProduct,
        isUpdated: true,
        series,
        harvestPeriods,
        storagePeriods,
      },
      time,
    );
  },
  storageDuration({ initialProduct, value, time }) {
    if (value != null && value < 0) {
      console.log('Invalid storage duration, update ignored', value);
      return initialProduct;
    }
    const initialValue = initialProduct.series[0].storageDays || 0;
    const updatedValue = (value || 0) * 7;

    const { series, storagePeriods } = initialProduct.series.reduce(
      (acc, serie) => {
        const storage = updatedValue && {
          begin: getStorageBegin(serie.harvest.end, updatedValue),
          end: getStorageEnd(serie.harvest.end, updatedValue),
        };
        const storageWeeks = storage ? getWeeksRecord(storage.begin, storage.end) : {};

        let computedSurfaceNeeds = serie.computedSurfaceNeeds;
        let expectedVolume = serie.expectedVolume;
        let needs = serie.needs;
        if (initialValue > updatedValue) {
          const [updatedNeeds, removedTotal] = removeNeedsOutsidePeriods(serie.needs, serie.harvestWeeks, storageWeeks);
          if (removedTotal) {
            needs = updatedNeeds;
            expectedVolume -= removedTotal;
            computedSurfaceNeeds = computeNeededSurface({
              expectedLostRate: serie.expectedLostRate,
              expectedYield: serie.expectedYield,
              volume: expectedVolume,
            });
          }
        }
        acc.series.push({
          ...serie,
          isUpdated: true,
          storageDays: updatedValue,
          storageWeeks,
          needs,
          expectedVolume,
          computedSurfaceNeeds,
        });
        acc.storagePeriods.push(
          storage
            ? {
                ...storage,
                weeks: storageWeeks,
              }
            : null,
        );
        return acc;
      },
      {
        series: [],
        storagePeriods: [],
      } as Pick<ChildProduct, 'series' | 'harvestPeriods' | 'storagePeriods'>,
    );

    return addOrRemoveSeriesOverlappingProjectPeriod(
      {
        ...initialProduct,
        isUpdated: true,
        series,
        storagePeriods,
      },
      time,
    );
  },
};

function shiftNeeds(initialNeeds: NumberMap, delta: number): NumberMap {
  const updatedNeeds: NumberMap = {};
  const originalSerieNeeds = Object.entries(initialNeeds);
  for (const [previousWeekKey, needToAdd] of originalSerieNeeds) {
    const [year, weekNumber] = previousWeekKey.split('-').map(Number);
    const previousWeek = buildWeek(year, weekNumber);
    const updatedWeek = getWeek(addWeeks(previousWeek.firstDay, delta));
    const updatedWeekKey = updatedWeek.year + '-' + updatedWeek.weekNumber;

    updatedNeeds[updatedWeekKey] = needToAdd;
  }
  return updatedNeeds;
}

/**
 *
 * @returns [updatedNeeds, removedTotal]
 */
function removeNeedsOutsidePeriods(
  initialNeeds: NumberMap,
  harvestWeeks: Record<string, boolean>,
  storageWeeks: Record<string, boolean>,
): [NumberMap, number] {
  // TODO how to manage needs where needsIndex === 2
  return Object.entries(initialNeeds).reduce(
    ([updatedNeeds, removedTotal], [weekKey, need]) => {
      if (harvestWeeks[weekKey] || storageWeeks[weekKey]) {
        updatedNeeds[weekKey] = need;
        return [updatedNeeds, removedTotal];
      } else {
        return [updatedNeeds, removedTotal + need];
      }
    },
    [{}, 0] as [NumberMap, number],
  );
}

function addOrRemoveSeriesOverlappingProjectPeriod(product: ChildProduct, time: MonthInterval): ChildProduct {
  const wasOverlapping = product.series.length === 2;
  const serie = product.series[0];
  const serieEnd = getSerieEnd(serie.harvest.end, serie.storageDays);
  const projectInterval = {
    start: time.weeks[0].firstDay,
    end: last(time.weeks).firstDay,
  };
  const serieBeginBeforeProject = !isWithinInterval(serie.begin.firstDay, projectInterval);
  const serieEndAfterProject = !isWithinInterval(serieEnd, projectInterval);
  const isOverlapping = serieBeginBeforeProject || serieEndAfterProject;
  // areIntervalsOverlapping(
  //   {
  //     start: serie.begin.firstDay,
  //     end: serieEnd,
  //   },
  //   projectInterval,
  // );
  if (wasOverlapping === isOverlapping) {
    return product;
  } else if (isOverlapping) {
    // const isNewSerieOverPeriodEnd = isWithinInterval(serieEnd, projectInterval);
    const newSerieHarvestBegin = getMatchingWeek(serie.harvest.begin, serieBeginBeforeProject);
    const newSerieHarvestPeriod = buildAvailabilityPeriod(
      newSerieHarvestBegin,
      getWeek(addDays(newSerieHarvestBegin.firstDay, serie.harvestDays - 1)),
    );
    const difference = differenceInDays(newSerieHarvestBegin.firstDay, serie.harvest.begin.firstDay);
    const newSerieStoragePeriod = serie.storageDays
      ? buildAvailabilityPeriod(
          getWeek(addDays(newSerieHarvestPeriod.end.firstDay, 7)),
          getWeek(addDays(newSerieHarvestPeriod.end.firstDay, 7 + serie.storageDays)),
        )
      : null;

    const newSerie: PepiniereSerie = {
      ...buildNewSerie({
        harvestPeriod: newSerieHarvestPeriod,
        maturationWeeks: serie.matureDays / 7,
        storageWeeks: serie.storageDays / 7,
        expectedYield: serie.expectedYield,
        isUpdated: true,
      }),
      storageDays: serie.storageDays,
      storageWeeks: newSerieStoragePeriod?.weeks || {},
      needs: Object.fromEntries(
        Object.entries(serie.needs).map(([weekKey, need]) => {
          const initialWeek = weekKeyToWeek(weekKey);
          const newWeek = getWeek(addDays(initialWeek.firstDay, difference));
          return [getWeekKey(newWeek), need];
        }),
      ),
    };
    const newSerieIndex = serieBeginBeforeProject ? 1 : 0;
    const updatedProduct: ChildProduct = {
      ...product,
      isUpdated: true,
      series: [...product.series],
      harvestPeriods: [...product.harvestPeriods],
      storagePeriods: [...product.storagePeriods],
    };
    updatedProduct.series.splice(newSerieIndex, 0, newSerie);
    updatedProduct.harvestPeriods.splice(newSerieIndex, 0, newSerieHarvestPeriod);
    updatedProduct.storagePeriods.splice(newSerieIndex, 0, newSerieStoragePeriod);
    return updatedProduct;
  } else {
    // TODO how to manage needs where needsIndex === 2
    // TODO remove serie
    const serieToRemoveIndex = product.series.findIndex(
      (serie) =>
        serie.begin.firstDay.getTime() > projectInterval.end.getTime() ||
        getSerieEnd(serie.harvest.end, serie.storageDays).getTime() < projectInterval.start.getTime(),
    );
    if (serieToRemoveIndex === -1) {
      console.log('WTF, serie to remove not found', product, time);
      return product;
    }
    const serieToRemove = product.series[serieToRemoveIndex];
    const updatedProduct: ChildProduct = {
      ...product,
      isUpdated: true,
      serieToRemoveId: serieToRemove.serieId || product.serieToRemoveId,
      series: [...product.series],
      harvestPeriods: [...product.harvestPeriods],
      storagePeriods: [...product.storagePeriods],
    };
    updatedProduct.series.splice(serieToRemoveIndex, 1);
    updatedProduct.harvestPeriods.splice(serieToRemoveIndex, 1);
    updatedProduct.storagePeriods.splice(serieToRemoveIndex, 1);
    return updatedProduct;
  }
}
