import { Without } from '@elzeard/common-components';
import { NumberMap, PlanningPosition } from '@elzeard/common-planning';
import { ClientCategoryEnum, LengthUnitEnum, SurfaceUnitEnum, toXSDDate } from '@elzeard/shared-dimensions';
import { add } from 'date-fns';
import { identity } from 'lodash';
import {
  PepiniereProjectItkInput,
  PlannedPositionInput,
  PlannedProductInput,
  PlannedPurchaseResaleInput,
  PlannedSeriesInput,
  ProductionNeedDetailInput,
  ProductionNeedInput,
  UpdatePepiniereProjectMutationVariables,
  WeeklyFloatInput,
} from '../common/generated/graphql';
import { BaseProjectPageState, ChildProduct, DistributionCircuit, OutletProductionNeeds, ParentProduct } from './state';

export function buildUpdateProjectInput(
  state: BaseProjectPageState,
): Required<Without<UpdatePepiniereProjectMutationVariables, 'farmId' | 'farmInput'>> {
  type UpdatedParentProduct = Pick<ParentProduct, 'name' | 'plantId' | 'quantityUnit' | 'isDisabled'> & {
    parentCropItineraryId: string;
    parentProductId: string;
    removedNeeds: string[];
    updatedOutletNeedsByClientId: [string, OutletProductionNeeds][];
    updatedChildProducts: ChildProduct[];
    updatedPurchaseResale: PlannedPurchaseResaleInput;
  };
  const updateItineraries: PepiniereProjectItkInput[] = Object.values(state.parentItinerariesById)
    .filter((itk) => itk.isUpdated)
    .flatMap((itk) => [
      {
        itkId: itk.id,
        objectInput: {
          label: itk.name,
        },
      },
      ...itk.children.map((child) => ({
        itkId: child.id,
        objectInput: {
          label: child.name,
        },
      })),
    ]);
  const {
    updatedParents,
    removedProductIds,
    removedPurchaseResaleIds,
    // unselectedParentCropItinerariesIds,
    useReferenceItineraries,
  } = Object.values(state.selectedParentProducts).reduce(
    (acc, parentProduct) => {
      if (parentProduct.isUpdated) {
        acc.updatedParents.push({
          parentCropItineraryId: parentProduct.parentCropItineraryId,
          parentProductId: parentProduct.productId,
          name: parentProduct.name,
          plantId: parentProduct.plantId,
          isDisabled: parentProduct.isDisabled,
          quantityUnit: parentProduct.quantityUnit,
          removedNeeds: parentProduct.removedNeeds.map((needs) => needs.productionNeedId).filter(identity),
          updatedOutletNeedsByClientId: Object.entries(parentProduct.productionNeedsByOutletRowId).filter(
            ([, outletNeeds]) => outletNeeds.isUpdated,
          ),
          updatedChildProducts: Object.values(parentProduct.selectedChildrenByRowId).filter(
            ({ isUpdated }) => isUpdated,
          ),
          updatedPurchaseResale: !parentProduct.purchaseResale?.isToBeDeleted &&
            parentProduct.purchaseResale && {
              id: parentProduct.purchaseResale.productId,
              name: parentProduct.parentCropItineraryId,
              hasProductionNeedInputs: [
                {
                  id: parentProduct.purchaseResale.productionNeedId,
                  needs: buildNeedsInput(parentProduct.purchaseResale.weeklyNeeds),
                  clientCategory: ClientCategoryEnum.OtherClientCategory,
                  // concernsClientInput: null,
                  // prices: null,
                  // unitPrice: null,
                },
              ],
              hasHarvestQuantityUnitInput: {
                id: parentProduct.quantityUnit,
              },
              hasPlantInput: parentProduct.plantId,
            },
        });

        if (parentProduct.isReferenceItinerary) {
          acc.useReferenceItineraries.push(parentProduct.parentCropItineraryId);
        }

        // TODO remove child products without childCropItineraryId
        acc.removedProductIds.push(
          ...Object.values(parentProduct.otherPossibleChildrenByChildItineraryId)
            .map(({ productId }) => productId)
            .filter(identity),
        );
        if (parentProduct.purchaseResale?.isToBeDeleted && parentProduct.purchaseResale.productId) {
          acc.removedPurchaseResaleIds.push(parentProduct.purchaseResale.productId);
        }
      }
      return acc;
    },
    // TODO garder les produits désélectionnés en les désactivant
    Object.values(state.otherPossibleParentProducts).reduce(
      (acc, parentProduct) => {
        if (parentProduct.productId) {
          acc.removedProductIds.push(
            parentProduct.productId,
            ...[
              ...Object.values(parentProduct.selectedChildrenByRowId),
              ...Object.values(parentProduct.otherPossibleChildrenByChildItineraryId),
            ]
              .map(({ productId }) => productId)
              .filter(identity),
          );
          if (parentProduct.purchaseResale?.productId) {
            acc.removedPurchaseResaleIds.push(parentProduct.purchaseResale.productId);
          }
        }
        // acc.unselectedParentCropItinerariesIds.push(parentProduct.parentCropItineraryId);
        const itk = state.parentItinerariesById[parentProduct.parentCropItineraryId];
        if (itk.isUpdated) {
          // nom modifié sur un itk inutilisé
          acc.useReferenceItineraries.push(parentProduct.parentCropItineraryId);
        }
        return acc;
      },
      {
        updatedParents: [] as UpdatedParentProduct[],
        removedProductIds: [] as string[],
        removedPurchaseResaleIds: [] as string[],
        // unselectedParentCropItinerariesIds: [] as string[],
        useReferenceItineraries: [] as string[],
      },
    ),
  );

  return {
    itksInput: {
      useReferenceItineraries,
      updateItineraries,
      // TODO deleteItineraries,
    },
    projectInput: {
      // unselectedParentCropItinerariesIds,
      hasOutletInputs: Object.values(state.outletsByRowId)
        .filter((outlet) => useReferenceItineraries.length || outlet.isUpdated)
        .map((outlet) => {
          const clientCategory = ClientCategoryEnum.OtherClientCategory; // TODO basket
          return {
            id: outlet.outletId,
            isDisabled: outlet.isDisabled,
            name: outlet.name,
            salesTarget: builWeeklyFloatsInputs(outlet.weeklySalesTarget),
            concernsClientInput: outlet.clientId
              ? {
                  id: outlet.clientId,
                }
              : {
                  name: outlet.name,
                  category: clientCategory,
                },
            hasDefaultPrices: Object.values(outlet.defaultPrices).map(
              ({ cropItineraryId, distributionCircuit, priceLevel, value }) => ({
                cropItineraryId,
                distributionCircuit,
                priceLevel,
                value: distributionCircuit === DistributionCircuit.Custom ? value : null,
              }),
            ),
            priceLevel: outlet.priceLevel,
            distributionCircuitPrices: outlet.distributionCircuit,
          };
        }),
      hasOutletInputsToDelete: state.removedOutlets.map((outlet) => outlet.outletId).filter(identity),
      hasPurchaseResaleInputsToDelete: removedPurchaseResaleIds,
      hasPurchaseResaleInputs: updatedParents.map((parent) => parent.updatedPurchaseResale).filter(identity),
      hasProductInputs: updatedParents.flatMap((parent) => [
        {
          id: parent.parentProductId,
          name: parent.name,
          hasCropItineraryInput: parent.parentCropItineraryId,
          hasHarvestQuantityUnitInput: { id: parent.quantityUnit },
          hasPlantInput: parent.plantId,
          hasProductionNeedInputs: parent.updatedOutletNeedsByClientId.map(([outletRowId, needs]) => {
            const outlet = state.outletsByRowId[outletRowId];
            const clientCategory = ClientCategoryEnum.OtherClientCategory; // TODO basket
            const input: ProductionNeedInput = {
              clientCategory,
              concernsClientInput: outlet.clientId
                ? { id: outlet.clientId }
                : {
                    name: outlet.name,
                    category: clientCategory,
                  },
              id: needs.productionNeedId,
              needs: buildNeedsInput(needs.weeklyNeeds),
              prices: buildNeedsInput(needs.weeklyPrices),
              unitPrice: needs.defaultUnitPrice,
            };
            return input;
          }),
          hasProductionNeedInputsToDelete: parent.removedNeeds,
        },
        ...parent.updatedChildProducts.map<PlannedProductInput>((child) => ({
          id: child.productId,
          name: child.parentCropItineraryId,
          hasCropItineraryInput: child.childCropItineraryId || child.parentCropItineraryId,
          hasCultureModeInput: { id: child.cultureMode },
          hasHarvestQuantityUnitInput: { id: child.quantityUnit },
          hasPlannedSeriesInputs: child.series
            .filter((serie) => serie.isUpdated || !child.productId)
            .map((serie) => {
              const input: PlannedSeriesInput = {
                id: serie.serieId,
                beginDate: toXSDDate(serie.begin.firstDay),
                endDate: toXSDDate(add(serie.harvest.end.firstDay, { days: 6, hours: 23, minutes: 59, seconds: 59 })),
                expectedLostRate: serie.expectedLostRate,
                hasNeededSurfaceInput: serie.editedSurfaceNeeds
                  ? {
                      value: serie.editedSurfaceNeeds,
                      unit: SurfaceUnitEnum.SquareMeter,
                    }
                  : null,
                matureDays: serie.matureDays,
                needs: buildNeedsInput(serie.needs),
                hasPositionInputs: serie.positions.map(buildPlanningPositionInput),
                hasCropProductionPeriodInputs: [
                  {
                    begin: toXSDDate(serie.harvest.begin.firstDay),
                    end: toXSDDate(add(serie.harvest.end.firstDay, { days: 6, hours: 23, minutes: 59, seconds: 59 })),
                    expectedYield: serie.expectedYield,
                    storageDays: serie.storageDays,
                  },
                ],
              };
              return input;
            }),
          hasPlannedSeriesInputsToDelete: child.serieToRemoveId ? [child.serieToRemoveId] : [], // TODO remove series if the dates changed and there is only 1 serie instead of 2 previously
          hasPlantInput: child.plantId,
          hasProductionNeedInputs: [],
        })),
      ]),
      hasProductInputsToDelete: removedProductIds,
    },
  };
}

function buildNeedsInput(needs: NumberMap): Array<ProductionNeedDetailInput> {
  return Object.entries(needs)
    .filter(([weekKey, volume]) => volume)
    .map(([weekKey, volume]) => {
      const [year, week] = weekKey.split('-').map(Number);
      return {
        year,
        week,
        volume,
      };
    });
}

function builWeeklyFloatsInputs(needs: NumberMap): Array<WeeklyFloatInput> {
  return Object.entries(needs).map(([weekKey, number]) => {
    const [year, week] = weekKey.split('-').map(Number);
    return {
      year,
      week,
      number,
    };
  });
}

export function buildPlanningPositionInput(position: PlanningPosition): PlannedPositionInput {
  return {
    ...(position.length
      ? {
          hasLengthPositionInput: { value: position.length, unit: LengthUnitEnum.Meter },
        }
      : {}),
    hasPositionSurfaceInput: {
      value: position.surface,
      unit: SurfaceUnitEnum.SquareMeter,
    },
    isLocatedInput: {
      id: position.bedId ? position.bedId : position.plotId,
      type: position.bedId ? 'CultivationBed' : 'Plot',
    },
    startPosition: position.start,
  };
}
