import { PlantFamilyColors } from '@elzeard/common-components';
import { NumberMap } from '@elzeard/common-planning';
import { HarvestUnitEnum } from '@elzeard/shared-dimensions';
import { ProductAvailability } from '../common/ProductAvailability';
import { BaseOutlet } from '../state';
import { OutletsPageStateDisplayOptions } from './state-displayOptions';

export const ALL_OUTLETS_ROW_ID = 'all-outlets';

export interface BaseOutletProduct {
  // properties copied from ParentProduct
  parentCropItineraryId: string;
  referenceParentItineraryId: string;
  name: string;
  quantityUnit: HarvestUnitEnum;
  plantFamilyColors: PlantFamilyColors;
  // specific properties
  rowId: string;
  availabilityPeriods: ProductAvailability[];
  numberOfSeries: number;
  /** Duplicated on each ProductionNeed */
  defaultUnitPrice: number;
  weeklyPrices: NumberMap;
  weeklyNeeds: NumberMap;
}

export interface OutletProduct extends BaseOutletProduct {
  weeklySales: NumberMap;
  totalSales: number;
  totalNeeds: number;
}
export interface Outlet extends BaseOutlet {
  totalSales: number;
  totalSalesTarget: number;
  weeklySales: NumberMap;
  products: Array<OutletProduct>;
}

export interface ComputedOutletsPageState {
  /** not editable in this early version */
  allOutlets: Outlet;
  // TODO unattributedOutlet: Outlet;
  outlets: Array<Outlet>;
}

export interface OutletsPageState extends OutletsPageStateDisplayOptions, ComputedOutletsPageState {}
